import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Button, Form, Layout, message, Spin, Typography } from 'antd'
import { useStores } from '../../../Store/Store'
import PassportStep from './PassportStep'
import { formLayout, legalAgeCapable } from '../../../Constants'
import '../Profile/ProfileStyle.css'
import Documents from '../Profile/Documents'
import { errors, profileScreen } from '../../../translates'
import {
  apiErrorCodes,
  AppConfig, individual,
  innDetailsStatusCodes,
  partnerDocStatusCodes,
  partnerPersonalDataStatusCodes,
  partnerStatusCodes, selfEmployed
} from '../../../AppConfig'
import {
  useMutatePartnerProfile,
  useMutatePartnerProfileCheck
} from '../../../api/react-query/partners'
import {
  createApiDataFromForm, getCorrectionStep, isFlowV2, isSectionEditable,
  scrollToFirstError,
  simpleReducer,
  validateAllPhotosUploading, validateFullInn
} from '../../../helper'
import AuthFillButtons from '../Profile/AuthFillButtons'
import InnStep from './InnStep'
import TaxServiceStep, { taxServiceSteps } from './TaxServiceStep/TaxServiceStep'
import SuccessStep from './SuccessStep/SuccessStep'
import { ConfigContext } from '../../../context/configContext'
import BankAcceptStep from './BankAcceptStep/BankAcceptStep'
import ErrorStep, { errorStepLocalStorageName } from './ErrorStep/ErrorStep'
import LegalAgeStep from './LegalAgeStep'
import NotFoundStep from './NotFoundStep'
import LegalAgeTemplateButton from './LegalAgeTemplateButton/LegalAgeTemplateButton'
import ExternalBlockedStep from './ExternalBlockedStep'

const { Content } = Layout
const { Title } = Typography

export const flowV2Steps = {
  passportStep: 'passportStep',
  legalAgeStep: 'legalAgeStep',
  innStep: 'innStep',
  medicalStep: 'medicalStep',
  taxServiceStep: 'taxServiceStep',
  bankAcceptStep: 'bankAcceptStep',
  errorStep: 'errorStep',
  notFoundStep: 'notFoundStep',
  externalBlockedStep: 'externalBlockedStep',
  successStep: 'successStep'
}

const initialState = {
  currentStep: null,
  innValue: null,
  taxServiceStepValue: null
}

function ProfileV2 () {
  const { setConfig } = useContext(ConfigContext)
  const [form] = Form.useForm()
  const { partnerProfile } = useStores()
  const [trigger, setTrigger] = useState(false)
  useEffect(() => {}, [setTrigger])
  const [existsNfc, setExistsNfc] = useState(partnerProfile?.profile?.personalData?.hasNfc)
  useEffect(() => {}, [setExistsNfc])
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})

  useEffect(() => {
    const personalData = partnerProfile?.profile?.personalData
    const innDetails = partnerProfile?.profile?.innDetails
    const profile = partnerProfile?.profile
    const inputs = profileScreen.input

    if (profile?.tinkoffData?.bankConfirmRequired) {
      setState({ currentStep: flowV2Steps.bankAcceptStep })
    } else if (profile?.statusCode === partnerStatusCodes.loginNotFound) {
      setState({ currentStep: flowV2Steps.notFoundStep })
    } else if (profile?.statusCode === partnerStatusCodes.externalBlocked) {
      setState({ currentStep: flowV2Steps.externalBlockedStep })
    } else if (personalData?.status === partnerPersonalDataStatusCodes.none && isSectionEditable(personalData)) {
      setState({ currentStep: flowV2Steps.passportStep })
    } else if (
      profile?.statusCode === partnerStatusCodes.new &&
      personalData?.status === partnerPersonalDataStatusCodes.filled &&
      (
        isSectionEditable(profile?.[inputs.legalCapacityConfirmation.name], [partnerDocStatusCodes.filled]) ||
        isSectionEditable(profile?.[inputs.trusteeAgreement.name], [partnerDocStatusCodes.filled]) ||
        isSectionEditable(profile?.[inputs.trusteePassport.name], [partnerDocStatusCodes.filled]) ||
        isSectionEditable(profile?.[inputs.trusteeConfirmation.name], [partnerDocStatusCodes.filled])
      )
    ) {
      setState({ currentStep: flowV2Steps.legalAgeStep })
    } else if (profile?.statusCode === partnerStatusCodes.correction) {
      setState({ currentStep: localStorage.getItem(errorStepLocalStorageName) ? getCorrectionStep(profile) : flowV2Steps.errorStep })
    } else if (
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.bindingConfirmed &&
      profile?.innDetails?.status === innDetailsStatusCodes.declined
    ) {
      setState({ currentStep: flowV2Steps.innStep })
    } else if (
      profile?.statusCode === partnerStatusCodes.verification &&
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.error &&
      profile?.personalData?.legalForm === selfEmployed &&
      // profile?.innDetails?.nameStatus === innDetailsNameStatusCodes.selffeeError &&
      !profile?.innPhoto?.fileId
    ) {
      setState({ currentStep: flowV2Steps.innStep })
    } else if (
      profile?.statusCode === partnerStatusCodes.verification &&
      personalData?.legalForm === individual &&
      !profile?.innPhoto?.fileId
    ) {
      setState({ currentStep: flowV2Steps.innStep })
    } else if (
      profile?.statusCode === partnerStatusCodes.verification &&
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.statusConfirmation &&
      !personalData?.legalForm
    ) {
      setState({ currentStep: flowV2Steps.innStep })
    } else if (
      [partnerStatusCodes.verification, partnerStatusCodes.verified].includes(profile?.statusCode) &&
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.statusConfirmation &&
      personalData?.legalForm === selfEmployed
    ) {
      setState({ currentStep: flowV2Steps.taxServiceStep, taxServiceStepValue: taxServiceSteps.lastStepSelfEmployed })
    } else if (
      [partnerStatusCodes.verification, partnerStatusCodes.verified].includes(profile?.statusCode) &&
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.bindingConfirmation
    ) {
      setState({ currentStep: flowV2Steps.taxServiceStep, taxServiceStepValue: taxServiceSteps.lastStepPartner })
    } else if (
      personalData?.status === partnerPersonalDataStatusCodes.filled &&
      profile?.passportPhoto1?.fileId &&
      profile?.passportPhoto2?.fileId &&
      innDetails?.status === innDetailsStatusCodes.none &&
      profile?.smz?.statusCode === AppConfig.smzStatusCodes.none
    ) {
      setState({ currentStep: flowV2Steps.innStep })
    } else if (profile?.statusCode === partnerStatusCodes.loginIssued) {
      setState({ currentStep: flowV2Steps.successStep })
    } else if (
      [partnerStatusCodes.verification, partnerStatusCodes.verified].includes(profile?.statusCode) &&
      (
        profile?.smz?.statusCode === AppConfig.smzStatusCodes.bindingConfirmed ||
        ([
          AppConfig.smzStatusCodes.error,
          AppConfig.smzStatusCodes.statusConfirmation
        ].includes(profile?.smz?.statusCode) && profile?.innPhoto?.fileId) ||
        (profile?.personalData?.legalForm === individual && profile?.innPhoto?.fileId)
      )
    ) {
      setState({ currentStep: flowV2Steps.taxServiceStep, taxServiceStepValue: taxServiceSteps.processing })
    } else if (profile?.smz?.statusCode === AppConfig.smzStatusCodes.error) {
      setState({ currentStep: flowV2Steps.taxServiceStep, taxServiceStepValue: taxServiceSteps.fail })
    }
  }, [partnerProfile?.profile])

  useEffect(() => {
    if (partnerProfile.form?.personalData?.mobilePhone) {
      form.setFieldsValue(partnerProfile.form)

      setTrigger(value => !value)
    }
  }, [form, partnerProfile.form])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: backendFieldsErrors[key]?.pathName,
        errors: backendFieldsErrors[key]?.errors
      })))
    }
  }, [backendFieldsErrors])

  const {
    data: profileChecked,
    mutate: doFetchProfileCheck,
    isError: isErrorProfileChecked,
    isLoading: isLoadingProfileChecked
  } = useMutatePartnerProfileCheck()

  useEffect(() => {
    if (profileChecked?.isSuccess) {
      partnerProfile.setPartnerProfile({
        ...profileChecked,
        profile: {
          ...profileChecked?.profile,
          personalData: {
            ...profileChecked?.profile?.personalData,
            status: partnerProfile?.profile?.personalData?.status
          }
        },
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
  }, [profileChecked, isErrorProfileChecked, partnerProfile])

  const {
    data: profileUpdated,
    mutate: updateProfile,
    isError: isErrorProfileUpdated,
    isLoading: isLoadingProfileUpdated
  } = useMutatePartnerProfile()

  useEffect(() => {
    if (profileUpdated?.isSuccess) {
      partnerProfile.setPartnerProfile({
        ...profileUpdated,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
    if (profileUpdated?.errorCode === apiErrorCodes.innDuplicate) {
      setBackendFieldsErrors({
        inn: {
          errors: [profileUpdated?.errorMessage],
          pathName: ['innDetails', profileScreen.input.inn.name]
        }
      })
    }
  }, [profileUpdated, isErrorProfileUpdated, partnerProfile])

  const isMarkFilled = useMemo(() => {
    let markFilled = false
    if (partnerProfile?.profile?.statusCode === partnerStatusCodes.correction) {
      if (
        state.currentStep === flowV2Steps.passportStep &&
        (partnerProfile?.profile?.personalData?.isLegalAgeCapable === undefined) &&
        [innDetailsStatusCodes.filled, innDetailsStatusCodes.accepted].includes(partnerProfile?.profile?.innDetails?.status) &&
        (
          partnerProfile?.profile?.personalData?.legalForm === selfEmployed || partnerProfile?.profile?.personalData?.legalForm === undefined ||
          (
            partnerProfile?.profile?.personalData?.legalForm === individual &&
            [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.egripPhoto?.status)
          )
        )
      ) {
        markFilled = true
      }
      if (
        state.currentStep === flowV2Steps.passportStep &&
        (partnerProfile?.profile?.personalData?.isLegalAgeCapable === false) &&
        [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.trusteeAgreement?.status) &&
        [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.trusteeConfirmation?.status) &&
        [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.trusteePassport?.status) &&
        [innDetailsStatusCodes.filled, innDetailsStatusCodes.accepted].includes(partnerProfile?.profile?.innDetails?.status) &&
        (
          partnerProfile?.profile?.personalData?.legalForm === selfEmployed || partnerProfile?.profile?.personalData?.legalForm === undefined ||
          (
            partnerProfile?.profile?.personalData?.legalForm === individual &&
            [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.egripPhoto?.status)
          )
        )
      ) {
        markFilled = true
      }
      if (
        state.currentStep === flowV2Steps.passportStep &&
        (partnerProfile?.profile?.personalData?.isLegalAgeCapable === true) &&
        [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.legalCapacityConfirmation?.status) &&
        [innDetailsStatusCodes.filled, innDetailsStatusCodes.accepted].includes(partnerProfile?.profile?.innDetails?.status) &&
        (
          partnerProfile?.profile?.personalData?.legalForm === selfEmployed || partnerProfile?.profile?.personalData?.legalForm === undefined ||
          (
            partnerProfile?.profile?.personalData?.legalForm === individual &&
            [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.egripPhoto?.status)
          )
        )
      ) {
        markFilled = true
      }
      if (
        state.currentStep === flowV2Steps.legalAgeStep &&
        [
          partnerPersonalDataStatusCodes.filled,
          partnerPersonalDataStatusCodes.accepted
        ].includes(partnerProfile?.profile?.personalData?.status) &&
        [innDetailsStatusCodes.filled, innDetailsStatusCodes.accepted].includes(partnerProfile?.profile?.innDetails?.status) &&
        (
          partnerProfile?.profile?.personalData?.legalForm === selfEmployed || partnerProfile?.profile?.personalData?.legalForm === undefined ||
          (
            partnerProfile?.profile?.personalData?.legalForm === individual &&
            [partnerDocStatusCodes.filled, partnerDocStatusCodes.accepted].includes(partnerProfile?.profile?.egripPhoto?.status)
          )
        )
      ) {
        markFilled = true
      }
    }
    if (state.currentStep === flowV2Steps.innStep) {
      markFilled = true
    }
    return markFilled
  }, [partnerProfile?.profile, state.currentStep])

  const onFinish = (values) => {
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    setBackendFieldsErrors({})

    if (state.currentStep === flowV2Steps.passportStep && partnerProfile?.profile?.statusCode === partnerStatusCodes.new) {
      values.personalData.citizenship = AppConfig.countries.Russia
    }
    let info = createApiDataFromForm(values)
    // Если коррекция - отправляем только поля, которые необходимо обновить (со statusReason)
    if (partnerProfile?.isCorrectionStatus) {
      Object.keys(partnerProfile?.profile).forEach(key => {
        if (!partnerProfile?.profile?.[key]?.allowEdit) {
          delete info[key]
        }
      })
    }

    if (info?.personalData?.withoutMiddleName) {
      info.personalData.middleName = ''
    }

    if (state.currentStep === flowV2Steps.innStep) {
      const legalForm = info?.personalData?.legalForm
      delete info?.personalData
      info = {
        ...info,
        ...(legalForm ? { personalData: { legalForm } } : {})
      }
    }

    info = { ...info, markFilled: isMarkFilled }
    if (isMarkFilled) {
      localStorage.removeItem(errorStepLocalStorageName)
    }
    updateProfile({ info })
  }

  const onFinishFailed = errorInfo => {
    scrollToFirstError(errorInfo?.errorFields)
    console.log('Failed:', errorInfo, errorInfo.values.personalData)
  }
  const onFieldsChange = (values) => {
    if (values[0]?.name?.join('.') === `innDetails.${profileScreen.input.inn.name}` && values[0]?.touched) {
      setState({ innValue: values[0]?.value })
    }
  }

  useEffect(() => {
    if (state.innValue?.length === 12) {
      validateFullInn({ inn: state.innValue }).then(res => {
        form.submit()
      }).catch(() => {})
    }
  }, [state.innValue])

  const filterDocByFlow = (doc) => {
    if ([flowV2Steps.taxServiceStep, flowV2Steps.successStep].includes(state.currentStep)) {
      return false
    }
    if (state.currentStep === flowV2Steps.passportStep) {
      return [profileScreen.input.passportPhoto1.name, profileScreen.input.passportPhoto2.name].includes(doc)
    }
    if (state.currentStep === flowV2Steps.legalAgeStep) {
      return partnerProfile?.profile?.personalData?.isLegalAgeCapable
        ? legalAgeCapable.listTrue.includes(doc)
        : legalAgeCapable.listFalse.includes(doc)
    }
    if (state.currentStep === flowV2Steps.innStep) {
      return [profileScreen.input.innPhoto.name, profileScreen.input.egripPhoto.name].includes(doc)
    }
    return true
  }

  const documentList = useMemo(
    () => AppConfig.documentNameList
      .filter(d => partnerProfile?.required?.[d] && partnerProfile?.profile?.[d]?.status !== partnerDocStatusCodes.accepted)
      .filter(filterDocByFlow),
    [partnerProfile?.required, state.currentStep, filterDocByFlow]
  )

  const filterDocuments = useCallback(
    (d) => {
      // if (
      //   partnerProfile?.profile?.smz?.statusCode === AppConfig.smzStatusCodes.bindingConfirmed ||
      //   partnerProfile?.profile?.innDetails?.nameStatus === innDetailsNameStatusCodes.selffeeError ||
      //   partnerProfile?.profile?.smz?.statusCode === AppConfig.smzStatusCodes.statusConfirmation
      // ) {
      //   return partnerProfile?.profile?.[d]?.visible
      // }
      return partnerProfile?.visible?.[d]
    },
    [partnerProfile?.visible]
  )

  const handleUpdateImage = (item, info) => {
    partnerProfile.updateForm({ ...partnerProfile?.form, ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(Math.random())
  }

  const handleUploadingImage = (item) => {
    if (item?.name === profileScreen.input.passportPhoto2.name) {
      // setExistsExtraReg(true)
    }
    setTrigger(Math.random())
  }

  const handleDeleteImage = (item) => {
    partnerProfile.updateForm({ ...partnerProfile?.form, ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(Math.random())
  }

  const handleUpdateForm = () => {
    partnerProfile.updateForm({
      ...partnerProfile?.form,
      ...form.getFieldsValue()
    })
  }

  const isShowAuthFillButtons = useMemo(() => {
    return !partnerProfile?.profile?.tinkoffData?.surname &&
      !partnerProfile?.profile?.sberIdData?.surname &&
      partnerProfile?.profile?.statusCode &&
      [flowV2Steps.passportStep].includes(state.currentStep) &&
      [
        partnerStatusCodes.new, partnerStatusCodes.free, partnerStatusCodes.draft
      ].includes(partnerProfile?.profile?.statusCode)
  }, [
    partnerProfile?.profile?.tinkoffData?.surname,
    partnerProfile?.profile?.sberIdData?.surname,
    partnerProfile?.profile?.statusCode,
    state.currentStep
  ])

  const mainTitle = useMemo(() => {
    const titleList = {
      [flowV2Steps.passportStep]: '1/2 Паспортные данные',
      [flowV2Steps.legalAgeStep]: '1/2 Паспортные данные',
      [flowV2Steps.innStep]: '2/2 Персональные данные',
      [flowV2Steps.medicalStep]: 'Медкнижка',
      [flowV2Steps.bankAcceptStep]: 'Реквизиты'
    }
    return titleList[state.currentStep] || ''
  }, [state.currentStep])

  useEffect(() => {
    setConfig({
      loadingData: {
        isLoadingFlowV2: isLoadingProfileUpdated,
        loadingText: <>Проверяем данные.<br />Это может занять какое-то время.</>
      }
    })
  }, [isLoadingProfileUpdated])

  const isShowSubmitButton = useMemo(() => {
    const stepsWithoutButton = [
      flowV2Steps.taxServiceStep,
      flowV2Steps.successStep,
      flowV2Steps.bankAcceptStep,
      flowV2Steps.errorStep,
      flowV2Steps.notFoundStep,
      flowV2Steps.externalBlockedStep
    ]
    if (state.currentStep && !stepsWithoutButton.includes(state.currentStep)) {
      if (state.currentStep === flowV2Steps.innStep) {
        return partnerProfile?.profile?.innDetails?.inn
      }
      return true
    }
    return false
  }, [state.currentStep, partnerProfile?.profile?.innDetails?.inn])

  const setFlowStep = (step) => {
    setState({ currentStep: step })
  }

  const helpModalForceDocList = useMemo(() => {
    return state.currentStep === flowV2Steps.innStep
      ? [profileScreen.input.innPhoto.name]
      : []
  }, [state.currentStep])

  return (
    <>
      <Title level={4}>{mainTitle}</Title>
      <Content className='Profile'>
        {isShowAuthFillButtons && <AuthFillButtons className='mb-3' />}
        <Form
          {...formLayout}
          name='basic'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          form={form}
          layout='vertical'
        >
          {state.currentStep === flowV2Steps.errorStep && (
            <ErrorStep setFlowStep={setFlowStep} />
          )}

          {state.currentStep === flowV2Steps.passportStep && (
            <PassportStep
              form={form}
              trigger={trigger}
              doFetchProfileCheck={doFetchProfileCheck}
            />
          )}

          {state.currentStep === flowV2Steps.legalAgeStep && (
            <LegalAgeStep />
          )}

          {state.currentStep === flowV2Steps.innStep && (
            <InnStep form={form} />
          )}

          {state.currentStep === flowV2Steps.taxServiceStep && (
            <TaxServiceStep
              profile={partnerProfile?.profile}
              step={state.taxServiceStepValue}
              onUpdateProfile={updateProfile}
              form={form}
              setFlowStep={setFlowStep}
            />
          )}

          {state.currentStep === flowV2Steps.successStep && (
            <SuccessStep />
          )}

          {state.currentStep === flowV2Steps.bankAcceptStep && (
            <BankAcceptStep />
          )}

          {state.currentStep === flowV2Steps.notFoundStep && (
            <NotFoundStep />
          )}

          {state.currentStep === flowV2Steps.externalBlockedStep && (
            <ExternalBlockedStep />
          )}

          <div style={{ marginBottom: '1rem' }} />

          {
            state.currentStep !== flowV2Steps.errorStep && (
              <Documents
                documents={documentList}
                trigger={trigger}
                form={form}
                title={null}
                required={partnerProfile?.required}
                filterDocuments={filterDocuments}
                onUpdate={handleUpdateImage}
                onUploading={handleUploadingImage}
                onDelete={handleDeleteImage}
                onPhotoDoc={handleUpdateForm}
                disabledUploadModal={!(partnerProfile?.profile?.showNfc && existsNfc) || !partnerProfile?.visible?.[profileScreen.input.passportPhotoExtraReg.name]}
                hidePhotoDoc
                showDocList
                showHelpModal
                helpModalForceDocList={helpModalForceDocList}
                hideButtonIfExistsDoc={isFlowV2(partnerProfile?.profile)}
              />
            )
          }

          <Spin spinning={isLoadingProfileChecked}>
            <Form.Item style={{ marginTop: '2rem' }}>
              {
                isShowSubmitButton && (
                  <Button
                    block
                    size='large'
                    type='primary'
                    htmlType='submit'
                    className='continue-button'
                  >
                    {profileScreen.button.continue}
                  </Button>
                )
              }
            </Form.Item>

            {state.currentStep === flowV2Steps.legalAgeStep && !partnerProfile?.profile?.personalData?.isLegalAgeCapable && (
              <LegalAgeTemplateButton />
            )}

          </Spin>
        </Form>
      </Content>
    </>
  )
}

export default ProfileV2
